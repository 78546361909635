.calculation-box {
    width: 150px;
    position: absolute;
    bottom: 40px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    font-size: small;
    opacity: 95%;
   text-align: left;
    }