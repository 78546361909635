body {
    background-color: #fafafa;
}

#map-container {
    margin-bottom: 10rem;
    margin-top: 2rem;
}

.holdridge-info {
    font-size: small;
    font-weight: 600;
    color: grey;
    margin-bottom: 0;
}
.holdridge-values {
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.results-card {
    height: 320px;
}